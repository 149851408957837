import axios from 'axios'
const state = {
    subjects: [],

}
const getters = {
    getSubjects(state) {
        return state.subjects
    },
}
const mutations = {
    setSubjects(state, subjects) {
        state.subjects = subjects
    },
}
const actions = {
    fetchSubjects({ commit,state}, queryParams) {
        return new Promise(async (resolve, reject) => {
            try {
                let params = {
                    paginated: false,
                };
                for (let key in queryParams) {
                    if (queryParams[key]) {
                      params[key] = queryParams[key];
                    }
                  }
                const subjects = await axios.get("/subject", {
                    params,
                });
                commit('setSubjects', subjects.data.data)
                resolve()
            } catch (err) {
                reject()
            } finally {

            }
        })
    }
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
}
