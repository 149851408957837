import axios from "axios";
const state = {
  subjects: [],
  units: [],
  partitions: [],
  lectures:[],
  subject: {},
  percent: null,
  loading: false,
  isLoading: false,
  tableOptions: {
    tableHeaders: [],
    tableData: [],
    accessTableData: [],
    loading: false,
    tableMeta: {
      total: 10,
      page: 1,
    },
  },
};
const getters = {
  getSubjects(state) {
    return state.subjects;
  },
  getSubject(state) {
    return state.subject;
  },
  getUnits(state) {
    return state.units;
  },
  getPartitions(state) {
    return state.partitions;
  },
  getLectures(state) {
    return state.units;
  },
  getTableOptions(state) {
    return state.tableOptions;
  },
  getLoadingStatus(state) {
    return state.loading;
  },
  getIsLoadingStatus(state) {
    return state.isLoading;
  },
  getPercent(state){
    return state.percent;
  },
};
const mutations = {
  setPercent(state, percent){
    state.percent = percent;
  },
  setSubjects(state, subjects) {
    state.subjects = subjects;
  },
  setUnits(state, units) {
    state.units = units;
  },
  setPartitions(state, partitions) {
    state.partitions = partitions;
  },
  setLectures(state, units) {
    state.units = units;
  },
  setSubject(state, subject) {
    state.subject = subject;
  },
  setLoading(state, status) {
    state.tableOptions.loading = status;
  },
  setLoadingStatus(state, status) {
    state.loading = status;
  },
  setIsLoadingStatus(state, status) {
    state.isLoading = status;
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders;
    state.tableOptions.tableData = tableInfo.tableData;
    state.tableOptions.accessTableData = tableInfo.accessTableData;
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total;
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page;
  },
};
const actions = {
  async fetchPercent({ commit ,state},queryParams){
    try {
      let params = {};
      for (let key in queryParams) {
        if (queryParams[key]) {
          params[key] = queryParams[key];
        }
      }
      const response = await axios.get("/subject", {
        params,
      });
      let percent = response.data.data.percent;
      console.log(percent);
      commit("setPercent", percent);
  
    } catch (error) {
      
    }
  },
  async fetchSubjects({ commit ,state}, queryParams) {
    try {
      commit("setIsLoadingStatus", true);
      commit("setLoading", true);
      console.log(state.isLoading);
      let params = {
        size: 10,
      };
      for (let key in queryParams) {
        if (queryParams[key]) {
          params[key] = queryParams[key];
        }
      }
      const response = await axios.get("/subject", {
        params,
      });
      commit("setSubjects", response.data.data);
      console.log(state.subjects);
      commit("setTableData", {
        tableHeaders: ["id", "name", "class", "settings"],
        tableData: response.data.data,
        accessTableData: [["id"], ["name"], ["grade", "name"]],
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
      commit("setIsLoadingStatus", false);
      console.log(state.isLoading);
    }
  },
  async fetchSubject({ commit, state }, subjectId) {
    try {
      commit("resetState");
      commit("setLoadingStatus", true);
      const response = await axios.get(`/subject/${subjectId}`);
      console.log("reg record faaam", response);
      let responseData = response.data.data;
      console.log(responseData);
      commit("setSubject", responseData);
    } catch (err) {
      console.log("errrr", err);
    } finally {
      commit("setLoadingStatus", false);
    }
  },
  async fetchUnits({ commit, state },queryParams) {
    try {
        commit("setLoading", true);
        let params = {
          size: 10,
        };
        for (let key in queryParams) {
          if (queryParams[key]) {
            params[key] = queryParams[key];
          }
        }
        const response = await axios.get("/unit", {
          params,
        });
      let responseData = response.data.data;
      commit("setUnits", responseData);
    } catch (err) {
      console.log("errrr", err);
    } finally {
      commit("setLoadingStatus", false);
    }
  },
  async fetchPartitions({ commit, state },queryParams) {
    try {
        commit("setLoading", true);
        let params = {
          size: 10,
        };
        for (let key in queryParams) {
          if (queryParams[key]) {
            params[key] = queryParams[key];
          }
        }
        const response = await axios.get("/subject-partition", {
          params,
        });
      let responseData = response.data.data;
      console.log(responseData);
      commit("setPartitions", responseData);
    } catch (err) {
      console.log("errrr", err);
    } finally {
      commit("setLoadingStatus", false);
    }
  },
  // async fetchLectures({ commit, state },queryParams) {
  //   try {
  //       commit("setLoading", true);
  //       let params = {
  //         size: 10,
  //       };
  //       for (let key in queryParams) {
  //         if (queryParams[key]) {
  //           params[key] = queryParams[key];
  //         }
  //       }
  //       const response = await axios.get("/lecture", {
  //         params,
  //       });
  //     console.log("reg record faaam setLectures", response);
  //     let responseData = response.data.lectures;
  //     console.log(responseData);
  //     commit("setLectures", responseData);
  //   } catch (err) {
  //     console.log("errrr", err);
  //   } finally {
  //     commit("setLoadingStatus", false);
  //   }
  // },
  async fetchLectures({ commit }, payload) {
    try {
      commit("setLoading", true);
      let params = {
        size: 10,
      };
      for (let key in payload.params) {
        if (payload.params[key]) {
          params[key] = payload.params[key];
        }
      }

      const response = await axios.get("/lecture", {
        params,
      });
      // console.log("reg record faaam fetchLectures", response);
      let responseData = response.data.data
      console.log("responseData fetchLectures",responseData);
      commit("setLectures", responseData);

    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchAllFloors({ commit }) {
    try {
      const floors = await axios.get("/floor", {
        params: {
          size: 100000,
        },
      });
      commit("setFloors", floors.data.data);
    } catch (err) {}
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
