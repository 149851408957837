<template>
    <div>
        <v-container id="regular-tables" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading" v-if="!driverRoute.routeState.loading">
                    <tr>
                        <th v-if="returnTrip" v-for="(name, i) in tableOptions.returnTableHeaders" :key="i">
                            {{ $t(name) }}
                        </th>
                        <th v-if="!returnTrip" v-for="(name, i) in tableOptions.goTableHeaders" :key="i">
                            {{ $t(name) }}
                        </th>
                    </tr>
                </thead>
                <div class="table__spinnerWrapper" v-if="driverRoute.routeState.loading">
                    <app-spinner></app-spinner>
                </div>
                <v-fade-transition mode="out-in" :group="true" tag="tbody">
                    <tr v-for="(item, idx) in tableOptions.tableData" :key="idx"
                        v-show="!driverRoute.routeState.loading">
                        <td v-if="!returnTrip">
                            <v-checkbox
                            class="mx-4 mb-4"
                            v-model="item.selected"
                            @change="checkSelected"
                            hide-details
                        ></v-checkbox>
                        </td>
                        <td>
                            <span>{{ item.id }}</span>
                        </td>
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            {{ new Intl.NumberFormat().format(item.price) }}
                        </td>
                        <td>
                            {{ item.driver.name }}
                        </td>
                        <td>
                            {{ item.driver.address }}
                        </td>
                        <td>
                            {{ item.driver.phone }}
                        </td>
                        <td>
                            <v-icon @click="$router.push(`routeInfo/${item.id}`)" color="green">mdi-eye</v-icon>
                            <delete-route :typeTrip="type" :item="item"></delete-route>
                            <edit-route :typeTrip="type" :item="item"></edit-route>
                        </td>

                    </tr>
                </v-fade-transition>
            </v-simple-table>
            <div class=" d-flex justify-end mt-5" v-if="!driverRoute.routeState.loading">
                <app-pagination :totalPages="tableOptions.tableMeta.total" :page="tableOptions.tableMeta.page"
                    @PaginationValue="fetchNewPaginated($event)"></app-pagination>
            </div>
        </v-container>
    </div>
</template>
  
<script>
import spinner from "./spinner.vue";
import pagination from "./pagination.vue";
import { driverRoute } from '../../store/driverRoutes'
import deleteRoute from '../../views/dashboard/busRoute/deleteRoute.vue'
import editRoute from '../../views/dashboard/busRoute/editRoute.vue'
export default {
    components: {
        appSpinner: spinner,
        appPagination: pagination,
        deleteRoute,
        editRoute
    },
    data() {
        return {
            driverRoute,
            deleteDialog: false,
            edit: false,
            item: null,
            type: null,
        };
    },

    props: {
        serverRoute: String,
        enableDelete: {
            type: Boolean,
            default: true,
        },
        flaggedItems: {
            type: Array,
            default() {
                return [];
            },
        },
        displaySettings: {
            type: Boolean,
            default: true,
        },
        returnTrip: {
            type: Boolean,
            default: false,
        },
        tableOptions: {
            type: Object,
            default: () => {
                return {};
            },
        },
        selected: {
            type: Object,
            default: () => {
                return {};
            },
        },
        
    },
    // computed: {
    //   ...mapGetters({
    //     tableOptions: "tablesStore/getTableOptions",
    //   }),
    // },
    methods: {
        checkSelected(){
            
            for (let i = 0; i < this.tableOptions.tableData.length; i++){
                if(this.tableOptions.tableData[i].selected==true){
                    this.selected.check=true
                    break
                }
                this.selected.check=false
            }
          

        },
        castItems(item) {
            let copyItem = { ...item };
            this.flaggedItems.forEach((el) => {
                delete copyItem[el];
            });
            return copyItem;
        },
        accessData(row, index) {
            return this.fetchAttributes(
                row,
                this.tableOptions.accessTableData[index]
            );
        },
        fetchAttributes(row, nestedKey) {
            let value = row;
            for (let key in nestedKey) {
                if (value[nestedKey[key]]) {
                    value = value[nestedKey[key]];
                } else {
                    return;
                }
            }
            return value.toLocaleString();
        },
        fetchNewPaginated(paginationValue) {
            this.$emit("re-fetch-paginated-data", paginationValue);
        },
        reFetchData() {
            this.$emit("re-fetch-data");
        },
    },
    created(){
        if(this.returnTipe){ this.type = "RETURN";} else { this.type = "GO" }
    },
};
</script>
  
<style >

</style>