import axios from "axios";
const date = new Date();
const getDefaultState = () => {
  return {
    loading: false,

    teachers: [],
    teacher: {
      name: {
        desc: "teacher",
        value: "",
      },
      birth_date: {
        desc: "birthday date",
        value: "",
      },
      joining_date: {
        desc: "Date of joining",
        value: "",
      },
      specialization: {
        desc: "specialization",
        value: "",
      },
      username: {
        desc: "username",
        value: "",
      },
      id: {
        desc: "id",
        value: "",
      },
    },
    days: [
      "saturday",
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
    ],
    tableOptions: {
      tableHeaders: [],
      tableData: [],
      accessTableData: [],
      loading: false,
      tableMeta: {
        total: 10,
        page: 1,
      },
    },
  };
};
const state = getDefaultState();

const getters = {
  getTeachers(state) {
    return state.teachers;
  },
  getTableOptions(state) {
    return state.tableOptions;
  },
  getDays(state) {
    return state.days;
  },
  getTeacherData(state) {
    return state.teacher;
  },

  getLoadingStatus(state) {
    return state.loading;
  },

  getTableLoading(state) {
    return state.tableOptions.loading;
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setLoadingStatus(state, status) {
    state.loading = status;
  },
  setTeacherInformation(state, teacherInfo) {
    state.teacher.name.value = teacherInfo.name;
    state.teacher.birth_date.value = teacherInfo.birth_date;
    state.teacher.joining_date.value = teacherInfo.joining_date;
    state.teacher.specialization.value = teacherInfo.specialization;
    state.teacher.username.value = teacherInfo.username;
    state.teacher.id.value = teacherInfo.user_id;
  },
  setTeachers(state, teachers) {
    state.teachers = teachers;
  },
  setLoading(state, status) {
    state.tableOptions.loading = status;
  },
  setTableData(state, tableInfo) {
    state.tableOptions.tableHeaders = tableInfo.tableHeaders;
    state.tableOptions.tableData = tableInfo.tableData;
    state.tableOptions.accessTableData = tableInfo.accessTableData;
    state.tableOptions.tableMeta.total = tableInfo.tableMeta.total;
    state.tableOptions.tableMeta.page = tableInfo.tableMeta.page;
  },
};
const actions = {
  async fetchAttachments({ commit, state }, payload) {
    try {
      console.log("fetchAttachments");
      commit("setLoading", true);
      let params = {
        size: 10,
      };
      for (let key in payload.params) {
        if (payload.params[key]) {
          params[key] = payload.params[key];
        }
      }
      const response = await axios.get("/attachment", {
        params,
      });
      const sizeInKb= response.data.data.map(item=>Math.round(item.size / 1000) + "kb")
      console.log("sizeInKb",sizeInKb);
      console.log(response);
      let tableHeaders = [];
      let accessTableData = [];
      tableHeaders = ["id","date", "name", "type", "size","user name","educitional class","subject","teacher", "settings"];
      accessTableData = [["id"],["date"], ["name"], ["mimetype"], ["size"],["user","username"],["edu_class"],["subject"],["teacher"]];
      
      //   commit("setTeachers", response.data.teachers);
      //   console.log("state.teachers", state.teachers);
      commit("setTableData", {
        tableHeaders,
        tableData: response.data.data,
        accessTableData,
        tableMeta: {
          total: response.data.meta.total,
          page: response.data.meta.current_page,
        },
      });
    } catch (err) {
      console.log("err", err);
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchTeacher({ commit, state }, teacherId) {
    try {
      commit("resetState");
      commit("setLoadingStatus", true);
      const response = await axios.get(`/teacher/${teacherId}`);
      console.log("reg record faaam", response);
      let responseData = response.data.data;

      commit("setTeacherInformation", responseData);
    } catch (err) {
      console.log("errrr", err);
    } finally {
      commit("setLoadingStatus", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
