import axios from 'axios'
const state = {
    tableOptions: {
        tableHeaders: [],
        tableData: [],
        accessTableData: [],
        loading: false,
        tableMeta: {
            total: 10,
            page: 1,
        },
    }
}
const getters = {
    getTableOptions(state) {
        return state.tableOptions
    },
}
const mutations = {
    setLoading(state, status) {
        state.tableOptions.loading = status
    },
    setTableData(state, tableInfo) {
        state.tableOptions.tableHeaders = tableInfo.tableHeaders
        state.tableOptions.tableData = tableInfo.tableData
        state.tableOptions.accessTableData = tableInfo.accessTableData
        state.tableOptions.tableMeta.total = tableInfo.tableMeta.total
        state.tableOptions.tableMeta.page = tableInfo.tableMeta.page
    }
}
const actions = {
    async fetchStudents({ commit }, queryParams) {
        try {
            commit('setLoading', true)
            let params = {
                size: 10,
            };
            for (let key in queryParams) {
                if (queryParams[key]) {
                    params[key] = queryParams[key]
                }
            }
            const response = await axios.get("/registration-record", {
                params,
            });
            response.data.data.forEach(el => {
                for (let key in el.student) {
                    if (key == "type") {
                        if (el.student[key] == "new") {
                            el[key] = "مستجد"
                        } else {
                            el[key] = "منقول"
                        }
                        continue
                    }
                    if (key == "gender") {
                        if (el.student[key] == "male") {
                            el.gender = "ذكر"
                        } else {
                            el.gender = "انثى"
                        }
                        continue
                    }
                    el[key] = el.student[key]
                }
            });
            let tableData = response.data.data.map((el) => {
                return {
                    public_record_number: el.public_record_number,
                    full_name: el.full_name,
                    mother_name: el.mother_name,
                    edu_class: el.edu_class,
                    gender: el.gender,
                    type: el.type,
                }
            })
           if(queryParams.paginated){
            commit('setTableData', {
                // "last name", "father name","grand father name",
                tableHeaders: [
                    "public record", "name",  "mother name", "educitional class", "gender", "type",
                ],
                tableData: tableData,
                // ["first_name"], ["last_name"], ["father_name"], ["grand_father_name"],
                accessTableData: [["public_record_number"], ["full_name"], ["mother_name"], ["edu_class"], ["gender"], ["type"],
                ],
                tableMeta: {
                    total: response.data.meta.total,
                    page: response.data.meta.current_page,
                },
            })
           } else {
            commit('setTableData', {
                // "last name", "father name","grand father name",
                tableHeaders: [
                    "public record", "name", "mother name", "educitional class", "gender", "type",
                ],
                tableData: tableData,
                // ["first_name"], ["last_name"], ["father_name"], ["grand_father_name"],
                accessTableData: [["public_record_number"], ["full_name"], ["mother_name"], ["edu_class"], ["gender"], ["type"],
                ],
                tableMeta: {
                    total: null,
                    page: null,
                },
            })
           }
        } catch (err) {
            console.log('err', err);
        } finally {
            commit('setLoading', false)
        }
    },
}


export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
}
